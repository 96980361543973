<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('makeup_letter_entry')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('makeup_letter_entry')"
                              :isFilter="false"/>
            </template>
            <div>
                <div class="row mb-4">
                    <div class="w-100 d-none d-lg-block"></div>
                    <div class="col-12 col-lg-3 col-xxl-3">
                        <div
                            class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100" style="height: calc(100vh - 220px)">
                            <div v-if="isInstructorSectionsList === true">
                                <div v-for="(item, key) in instructorSectionsList" :key="key">
                                    <b-button :variant="
                      (current_course && current_course[0].student_course.course_code === item[0].student_course.course_code ? 'outline-primary' : 'outline-secondary') +
                      ' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 h-auto'"
                                        @click="getStudentCourseEntry(item)"
                                    >
                                        {{ item.length > 0 && key + " - " + item[0].student_course.course_name }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="spinner-border text-light mx-auto d-flex"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-9 col-xxl-9">
                        <div v-if="isTable === true">
                            <div class="row align-items-center">
                                <div class="col-12 mb-3">
                                    <h5 class="mb-md-0">
                                        {{
                                          current_course[0].student_course.course_code+' - '+current_course[0].student_course.course_name
                                        }}
                                    </h5>
                                </div>
                            </div>

                            <b-table
                                :empty-filtered-text="this.$t('table_not_found')"
                                :empty-text="this.$t('table_is_empty')"
                                bordered
                                striped
                                responsive
                                :items="students"
                                :fields="fieldsStudent"
                                class="mb-0 table-dropdown no-scrollbar border rounded"
                            >
                                <template #cell(exams)="row">
                                    <b-form-select
                                        @input="handleInput(row.item.makeup_exam_id, $event)"
                                        :value="row.item.letter_grade"
                                        :options="grades(row.item.grades)"
                                    ></b-form-select>
                                </template>
                            </b-table>

                            <div class="d-flex mt-4">
                                <b-button variant="primary" class="mr-2" @click="save">{{ $t("save") }}</b-button>
                            </div>
                        </div>
                        <div v-else-if="isTable === null">
                            <div class="spinner-border text-light mx-auto d-flex"></div>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import MakeUpExamService from "@/services/MakeUpExamService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t("makeup_letter_entry"),
        };
    },
    data() {
        return {
            instructorSectionsList: [],
            isInstructorSectionsList: false,
            current_course: null,
            fieldsStudent: [
                {
                    key: "student_number",
                    label: this.toUpperCase("student_number"),
                    sortable: true,
                },
                {
                    key: "name",
                    label: this.toUpperCase("name_surname"),
                },
                {
                    key: "class",
                    label: this.toUpperCase("class"),
                },
                {
                    key: "exams",
                    label: this.$t("grade"),
                    sortable: false,
                    thClass: 'width-100'
                }
            ],
            students: [],
            isStudents: false,
            isTable: "",
            size: "md",
            student_note: "",
            current_student: "",
        };
    },

    methods: {
        grades(opitons) {
            let data = [];
            opitons.map((item) => {
                data.push({value: item, text: item});
            });
            let opitonData = [{value: null, text: this.$t("select")}, ...data];
            return opitonData;
        },

        handleInfo(student) {
            this.student = student;
            this.student_note = student.instructors_note;
            this.showModal("noteModal");
        },

        handleInput(makeup_exam_id, value) {
            this.students.forEach((itm, index) => {
                if(itm.makeup_exam_id == makeup_exam_id){
                    this.students[index].letter_grade = value
                }
            })
        },

        getInstructorStudents(){
            this.isInstructorSectionsList = false;
            MakeUpExamService.getInstructor().then((response) => {
                this.isInstructorSectionsList = true;
                this.instructorSectionsList = response.data.data;
            }).catch((e) => {
                this.$toast.error(e.data.message);
            })
        },

        getStudentCourseEntry(course) {
            this.students = []
            this.isTable = null;
            this.current_course = course;
            course.forEach(itm => {
                this.students.push({
                    student_number: itm.student_program.student.student_number,
                    name: itm.student_program.student.person.name+' '+itm.student_program.student.person.surname,
                    class: itm.student_program.class,
                    letter_grade: itm.letter_grade,
                    makeup_exam_id: itm.id,
                    grades: itm.grades
                })
            })
            this.isTable = true
        },

        save() {
            if(this.checkPermission('makeupexam_entrylettergrade')){
                this.$swal
                    .fire({
                        text: this.$t('makeup_letter_grade_entry_save_msg'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            let data = [];
                            this.students.forEach((item) => {
                                data.push({
                                    make_up_exam_id: item.makeup_exam_id,
                                    letter_grade: item.letter_grade
                                });
                            });
                            MakeUpExamService.entryLetterGradeStore({data: data}).then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            })
                        }
                    })
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
            }

        },
    },
    created() {
        this.getInstructorStudents()
    },
}
</script>
